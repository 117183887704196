@import "../defaults";

.wing {

    .wing-name-wrapper {
        @include box;
        width: calc(100% - 55px);
        position: relative;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 17px;
        margin-left: 25px;

        .wing-name {
            font-size: 3em;
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: 15px;
        }
    }
}
