.role {
    padding: 0 10px;
    text-align: center;
    position: relative;
    user-select: none;

    &.replacement {
        background-color: yellow;
    }

    &.missing {
        background-color: red;
    }

    &.hidden {
        display: none;
    }

    .task, .profession, .player {

    }

    .profession {
        height: 54px;
        vertical-align: middle;
        display: inline-flex;
        justify-content: space-around;
        flex-direction: column;

        img {
            height: 50px;
        }
    }
    .player {
        cursor: pointer;
        user-select: none;
    }

    .player-select {
        display: flex;
        position: absolute;
        flex-direction: column;
        background-color: beige;
        border-radius: 5px;
        cursor: auto;
        width: calc(100% - 20px);
        z-index: 10;
    }
    .player-select span {
        cursor: pointer;
        border: grey 1px solid;
        margin: 2px;
        border-radius: 5px;
        user-select: none;
    }
}


.boss.small .task {
    display: none;
}
.boss.small .player {
    display: none;
}