@import "./defaults";

html,
body {
    height: 100%;
}

body {
    height: 100vh;
    padding: 0;
}

body {
    background: url("https://d3b4yo2b5lbfy.cloudfront.net/wp-content/uploads/2015/08/ac9e5Gorseval-1.jpg") no-repeat fixed 50% 50%;
    background-size: cover;
}

#root {
    height: 100%;

    .App {
        height: 100%;
        display: flex;
        flex-direction: column;

        header {
            text-align: center;
            background-color: $background-grey;
            height: 100px;
        }

        main {
            display: inline-block;
            height: calc(100% - 105px);
        }
    }
}
