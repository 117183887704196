@import "../defaults";

section.boss {
    @include box;
    margin-bottom: 15px;

    &.no-icon {
        padding-left: 72px;
    }

    > img {
        display: inline-block;
        height: 50px;
        margin-left: 22px;
        border-radius: 3px;
        vertical-align: top;
    }
    span.boss-name {
        display: inline-block;
        line-height: 50px;
        font-size: 20px;
        margin-left: 10px;

        cursor: pointer;
        user-select: none;
        vertical-align: top;
        width: 200px;
    }
}