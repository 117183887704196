
.boss {
    .setupSelector {

        &.closed.changed {
            .setup-name {
                background-color: #ffc135;
            }
        }

        .setup {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;

            &.inactive {
                display: none;
            }
            &.backup-setup {
                background-color: #ffc135;
            }
            &.missing-setup {
                background-color: #ff1300;
            }

            .setup-name {
                order: 0;
                display: flex;
                width: 175px;
                cursor: pointer;
                user-select: none;
                >* {
                    margin: auto;
                }
            }
        }
    }

    &.big {
        .setupSelector {

            &.closed {
                .setup.active {
                    border-bottom-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                }
            }
            &.open {
                .setup.active {
                    &:last-child {
                        border-bottom-left-radius: 25px;
                        border-bottom-right-radius: 25px;
                    }
                }
            }
        }
    }
    &.small {
        .setupSelector {
            &.closed {
            }
            .setup.active {
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
            }
            &.open {
                .setup.active {
                    &:first-child {
                        border-top-right-radius: 25px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 25px;
                    }
                }
            }
        }
        .setupSelector {
            display: inline-block;
            margin-left: 10px;

            .setup-roles {
                height: 50px;
            }
        }
    }
}