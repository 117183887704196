.setup-roles {
    order: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    &.hidden {
        display: none;
    }
}