@import "./defaults";

.body {
  display: flex;
  flex-direction: row;
  height: 100%;

  .content {
    height: 100%;
    overflow: auto;
    padding-right: 40px;
    flex-grow: 1;

    >h3 {
      @include box;

      text-align: center;
    }
  }
}