// _defaults.scss
$box-shadow-width: 2px !default;
$background-grey: rgba(220,220,220,0.6);


@mixin box {
  background-color: $background-grey;
  box-shadow: $box-shadow-width $box-shadow-width darkgray;
  padding: 2px;
  border-radius: 25px;
}