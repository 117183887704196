@import "../defaults";

$border: black 1px solid;

.sidebar {
  @include box;
  height: 100%;
  margin-right: 20px;
  flex-grow: 0;
  padding: 2px 10px;
  border-radius: unset;
  box-shadow: none;
  min-width: 200px;

  .expand-all {
    @include box;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }

  .sidebar-menu {
    border: $border;
    margin-bottom: 35px;

    .menu-selector {
      margin: 0;
      display: flex;

      .selector {
        border-right: $border;
        border-bottom: $border;
        cursor: pointer;
        padding: 2px 10px;
        flex-grow: 1;
        text-align: center;

        &:last-child {
          border-right: none;
        }

        &.active {
          border-bottom: none;

        }
      }
    }

    .submenu {
      padding: 15px 0;
      margin: 0;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 300px;

      > ul {
        margin: 0;
      }
    }
  }
}